<div omgCollapse
     [disabled]="disabled"
     [expanded]="expanded"
     class="collapsible-banner"
     [ngClass]="variantClass"
     (expandedChange)="expandChange.emit($event)"
     #collapseRef="omgCollapse">
  <div om-layout
       omgCollapseToggle
       om-layout="vertical">
    <div class="collapse-toggle">
      <div om-flex
           class="header plain-text">
        <ng-content select="[header]"></ng-content>
      </div>
      <div om-flex="65"
           om-layout
           om-layout-gutter
           om-layout-align="end"
           class="severity-title">
        <ng-content select="[severity-title]"></ng-content>
        <div *ngIf="!disabled">
          <omg-collapsed disableAnimation="true"><i class="fa fa-chevron-down"></i>
          </omg-collapsed>
          <omg-expanded disableAnimation="true"><i class="fa fa-chevron-up"></i>
          </omg-expanded>
        </div>
      </div>
    </div>
    <omg-expanded om-layout="vertical"
                  class="message"
                  [ngClass]="messageVariant">
      <ng-content select="[message]"></ng-content>
    </omg-expanded>
  </div>
</div>
