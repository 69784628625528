// @ts-strict-ignore
import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormControl } from '@angular/forms';
import { take, tap } from 'rxjs/operators';

import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';
import { buildCheckoutMessages } from '@app/modules/rx-cart/shared/rx-cart-utils';

import { RenewalsApiService } from '../../shared/renewals-api.service';
import { CheckoutRenewal } from '../../shared/renewals.type';

@Component({
  selector: 'omg-renewal-checkout-list-item',
  templateUrl: './renewal-checkout-list-item.component.html',
  styleUrls: ['./renewal-checkout-list-item.component.css'],
})
export class RenewalCheckoutListItemComponent implements OnInit {
  @Input() renewal: CheckoutRenewal;
  readyToSign = new UntypedFormControl();
  handwrittenConfirmed = new UntypedFormControl();
  checkoutMessages = buildCheckoutMessages();

  private destroyRef = inject(DestroyRef);

  constructor(
    private renewalsApiService: RenewalsApiService,
    private launchDarklyService: LaunchDarklyService,
  ) {}

  ngOnInit() {
    this.launchDarklyService
      .variation$(FeatureFlagNames.medsPDMP, false)
      .pipe(
        tap(showPdmp => {
          if (showPdmp) {
            this.renewal.readyToSign = true;
          } else {
            this.readyToSign.valueChanges
              .pipe(takeUntilDestroyed(this.destroyRef))
              .subscribe(checked => {
                if (checked) {
                  this.renewalsApiService
                    .createReadytoSignEvent(this.renewal)
                    .pipe(take(1))
                    .subscribe();
                }
                this.renewal.readyToSign = checked;
              });
          }
        }),
        take(1),
      )
      .subscribe();
    this.handwrittenConfirmed.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(checked => {
        this.renewal.handwrittenConfirmed = checked;
      });
  }
}
