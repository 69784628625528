import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { CollapseDirective } from '@app/shared';

export type BannerVariant =
  | 'info'
  | 'instructional'
  | 'error'
  | 'warning'
  | 'success';
export type MessageVariant = 'without-padding';

@Component({
  selector: 'omg-collapsible-banner',
  templateUrl: './collapsible-banner.component.html',
  styleUrls: ['./collapsible-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapsibleBannerComponent {
  @Input() variant: BannerVariant;
  @Input() messageVariant: MessageVariant;
  @Input() expanded: boolean;
  @Input() disabled: boolean;

  @Output() expandChange = new EventEmitter<boolean>();

  @ViewChild('collapseRef', { static: true })
  private collapseDirective: CollapseDirective;

  get isExpanded(): boolean {
    return this.collapseDirective.expanded;
  }

  constructor() {}

  get variantClass(): string {
    const clickableClass = !this.disabled ? ' clickable' : '';
    return `-${this.variant}${clickableClass}`;
  }
}
